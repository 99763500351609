// @flow

import React, { useState, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { ImportantInformation } from '@latitude/important-information';
import { Box } from '@latitude/box';
import { FeaturesSlider } from '@latitude/features-slider';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { StickyCta } from '@latitude/sticky-cta';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { BREAKPOINT, COLOR } from '@latitude/core/utils/constants';
import Layout from '../../components/layout';

import PageData from '../../data/pages/debt-consolidation-loans.json';
import footerData from '../../data/json/footer.json';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import Button from '../../components/Button/Button';
import LoanRates from '../../components/LoanRates/LoanRates1';
import CalculatorTile from '../../components/Calculators/CalculatorTile';
import PageHeaderSection from '@/components/PageLayout/PageHeaderSection';
import { featuresSliderIE11Fix } from '../../utils/stylesUtils';
import LoanProcessList from '../../components/LoanProcessList/LoanProcessList';
import { LOAN_PURPOSE } from '../../utils/constants';
import EstimateRateWidgetSection from '../../components/EstimateRateWidgetSection/EstimateRateWidgetSection1';
import useApplyUrl from '../../hooks/useApplyUrl';
import SoftQuoteContext from '../../context/SoftQuoteContext';
import HowToApplyEligibility from '../../components/HowToApplyEligibility/HowToApplyEligibility1';
import Faq from '../../components/Faq/Faq';
import FeesAndCharges from './_LoanFeesCharges';
import heroImage from '../../images/hero-debt-consolidation-loan.webp';
import SvgInline from '@latitude/svg-inline';
import { PageContext } from '../../context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';

/*
 * Debt Consolidation Loan Page
 */
const DebtConLoanPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const { applyUrl, setApplyUrl } = useApplyUrl();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [
    {
      heading: 'Why choose a Gem Loan?',
      featureCards: PageData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  return (
    <Layout noMetaKeywords customFooter={footerData} hasStickyNav>
      <MobileAppInstallPrompts />
      <main className="navigation-spacer navigation-spacer--hasStickyNav personal-loan-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/loans/debt-consolidation-loans/"
            />
            <title>
              Debt Consolidation Loans | Consolidate Your Debt | Gem by gem
              finance
            </title>
            <meta
              name="description"
              content="Our debt consolidation loan in New Zealand can help you consolidate your debts into one easy to manage payment. Get a quote online in under 2 minutes."
            />
          </Helmet>

          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title="Debt Consolidation Loans"
              subtitle={
                <>
                  Keep things simple by combining multiple repayments into one.
                  Apply for a Gem Debt Consolidation Loan online in 7 minutes
                  <span class="conditional-text">
                    {' '}
                    and get a response to your application in only 60 seconds
                  </span>
                  .
                </>
              }
            >
              <div className="row text-white HeroContent__insettext">
                <div className="col-6 col-md-5 text-left">
                  <HomeButton
                    href={applyUrl}
                    trackId="personal-loans-button"
                    trackEventData={{
                      label: 'Get rate estimate',
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLNZLF-WEB']}
                    className="button--primary"
                  >
                    Get rate estimate
                  </HomeButton>
                </div>
                <div className="col-1 p-0 text-center">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="col-5 col-md-6 text-left">
                  Check your interest rate in 2 minutes with no impact to your
                  credit score.
                </div>
              </div>
              <div className="row text-white HeroContent__insetdisclaimer">
                <div className="col-12 mt-2">
                  Credit and lending criteria, and fees apply. You may wish to
                  seek independent advice to ensure this product is right for
                  your personal circumstances. Credit provided by Latitude
                  Financial Services Limited.
                </div>
              </div>
            </PageHeaderSection>
          )}
          <SoftQuoteContext.Provider value={{ applyUrl, setApplyUrl }}>
            <div className="d-lg-none d-xl-none">
              <StickyCta
                href={applyUrl}
                trackId="sticky-get-started-debt-con"
                text="Get rate estimate"
              />
            </div>

            <div className="d-none d-lg-block">
              <StickyNavigation
                items={PageData.nav}
                phoneNumber={PageData.content.phoneNumber}
                ctaHref={applyUrl}
                ctaTarget="_self"
                ctaText="Get rate estimate"
                isSticky={isNavSticky}
                onStickyNavStateChange={setIsNavSticky}
                offsetElem="[data-sticky-navigation-offset]"
                trackId="sticky-nav-get-started"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  label: 'Get rate estimate',
                  location: 'Sticky Navigation'
                }}
                trackProductId={['PLNZLF-WEB']}
              />
            </div>

            <EstimateRateWidgetSection
              purpose={LOAN_PURPOSE.CONSOLIDATION}
              trackProductId={['PLNZLF-WEB']}
            />
          </SoftQuoteContext.Provider>

          <LoanProcessList
            title={PageData.content.loanProcess.title}
            data={PageData.content.loanProcess.list}
            id="how-it-works"
          />

          <div
            css="
              position: relative;
              z-index: 1;
              margin-top: -40px !important" /* As per UX request */
          >
            <LoanRates
              isBranded
              rateBoxType="personalSym"
              rateBoxType2="personalUnsecuredSym"
              fees={PageData.content.loanDetails1}
              extraTCs="true"
            />
          </div>

          <FeesAndCharges data={PageData.content.fees} />

          {/* css as per UX request */}
          <div
            css={`
              @media (max-width: ${BREAKPOINT.LG}) {
                padding-bottom: 50px;
              }
            `}
          >
            <CalculatorTile />
          </div>

          {featureSliderData[0] && (
            <FeaturesSlider
              heading={featureSliderData[0].heading}
              subheading={featureSliderData[0].description}
              data={featureSliderData[0].featureCards}
              id="why-us"
              className="why-choose bg-f8f8f8"
              backgroundColor={COLOR.WHITE}
              css={featuresSliderIE11Fix}
            />
          )}

          <HorizontalRule />

          <HowToApplyEligibility />
          <Faq data={PageData.content.faq} autoHeightOnDesktop />
          <ImportantInformation
            data={require('../../data/pages/debt-consolidation-loans.json')}
            sectionOneColummClasses="col-10 offset-1"
            id="additional-payments"
          />
        </Box>
      </main>
    </Layout>
  );
};

const HomeButton = styled(Button)`
  && {
    padding: 15px 45px;
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

export default DebtConLoanPage;
